import './App.css';
import {AppRouter} from "./modules/router/router";
import React from 'react';
import './i18n';
const App = () => {
  return <AppRouter />;
};


export default App;
