import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Swiper,SwiperSlide} from 'swiper/react';
import {Navigation,Pagination,A11y,Scrollbar , EffectCoverflow} from 'swiper'
import 'swiper/swiper-bundle.min.css';
import React ,{useEffect,useState} from "react";
import './REEF.css'
import Network from "../../modules/Network/Network";
import Loading from "../../components/Loading/Loading";
import Error from "../../components/Error/Error";
import reef from "../../assets/reef.png";
import { useTranslation } from "react-i18next";

const REEF= () => {
    const [t,i18n] = useTranslation();
    const [dataa, setdataa] = useState([]);
    const [HWA , setHWA] = useState([]);
    const [load, setload] = useState(false);
    const [showError, setshowError] = useState(true);
    const [openModal , setOpenModal] =useState(false);
    const [modalContent,setModalContent] = useState([]);
    const [filterType , setFilterType]=useState("");
    const FilteredCat = dataa.filter((dataa)=> dataa.Reef_Type !== "1")
    const Filtered = FilteredCat.filter((dataa)=>
    {
        if(filterType === "Agriculture")
        {
            
            return dataa.Category === "Agriculture";
            
          
        }
        else if(filterType === "Environmental-Friendly Residence")
        {    
            return dataa.Category === "Environmental-Friendly Residence"
        }
        else if(filterType === "Renewable Energy Applications")
        { 
            return dataa.Category === "Renewable Energy Applications"
        }
        else
        {
            return dataa;
            
        }
    })
    const FilteredVisit = dataa.filter((dataa)=> dataa.Reef_Type === "1")
    const changeModalContent = (card) => {
        setModalContent([card]);
        setOpenModal(true);
    }

    useEffect(() => {
        localStorage.getItem("Lang") === 'UK' ? i18n.changeLanguage('en') : i18n.changeLanguage('ar');
        Network.REEF.getREEF()
        .then(function (response)
        {   
           setdataa(response.data['data']['REEF']);
           setHWA(response.data['data']['Who_We_Are'].translations[0]);
           setload(true);
        })
        .catch(function (error) {
          console.log(error);
          setshowError(false)
        });
    }, [])
        return (
        <div className="container" style={{position:"relative"}}>
            <h2 className={localStorage.getItem("Lang") === 'UK' ? "eng": "arb"}>
            {t('REEF.title')}
            </h2>
            
            <div id="REEFContainer">
            <h3 className={localStorage.getItem("Lang") === 'UK' ? "eng": "arbSub"} style={{borderLeft: "5px solid #0a2d51",marginLeft: "15px",marginTop: "35px",paddingLeft:"5px"}}> {t('REEF.Who')}</h3> 
            <div id="REEFIntro" style={localStorage.getItem("Lang") === 'UK' ? {}: {textAlign:"right"}}>
            {HWA.Who_We_Are}
            </div>
            <img id={localStorage.getItem("Lang") === 'UK' ? "reefImg": "reefImgArb"} src={reef} alt=""/>
            <h3 className={localStorage.getItem("Lang") === 'UK' ? "eng": "arbSub"} style={{borderLeft: "5px solid #0a2d51",marginLeft: "15px",paddingLeft:"5px"}}> {t('REEF.what')}</h3>
            <div id="REEFTypesContainer">
                
            <div id="REEFTypes">
            <div className="input-container" id="Agriculture" >
                <input id="allRadio"  className="radio-button" value="Agriculture" onChange={e=>setFilterType(e.target.value)}  type="radio" name="radio"/>
            <div className="radio-tile">
                    <label htmlFor="Agriculture" className="radio-tile-label"> {t('REEF.Agriculture')}</label>
                </div>
            </div>
            <div className="input-container" id="Renewable">
                <input id="solarRadio"  className="radio-button" onChange={e=>setFilterType(e.target.value)}  value="Environmental-Friendly Residence" type="radio" name="radio" />
            <div className="radio-tile">
                    
                    <label htmlFor="Renewable" className="radio-tile-label"> {t('REEF.Environmental')}</label>
                </div>
            </div>
            <div className="input-container" id="Livestock">
                <input id="windRadio"  className="radio-button" onChange={e=>setFilterType(e.target.value)} value="Renewable Energy Applications" type="radio" name="radio"/>
            <div className="radio-tile">
                    
                    <label htmlFor="Livestock" className="radio-tile-label"> {t('REEF.Livestock')}</label>
                </div>
            </div>
            
            </div>
       
                <div id="REEFCardTypes">
                    {showError ?
                (load ? Filtered.map(
                    card => (

                    <div className="REEFCard" key={card.id} style={{backgroundImage:`url(${Network.REEF.getImages()+card.Background_Image['id']})`,backgroundSize:"cover"}}>
                                <div className="REEFContent">
                                <h4 className="REEFTitle">{card.translations[0] ? card.translations[0].Title : "Title"}</h4>
                                <span className="ExplorBtn" onClick={() => {changeModalContent(card)}}> {t('REEF.btn')}<span><FontAwesomeIcon icon={faArrowRight} /></span></span>
                                </div>
                    </div>
                )
                ):<Loading />) 
                : 
                (<Error />)
            } 
                </div>
                
            </div>

            <br></br>
            <h3 className={localStorage.getItem("Lang") === 'UK' ? "eng": "arbSub"}  style={{borderLeft: "5px solid #0a2d51",marginLeft: "15px", paddingLeft:"5px"}}> {t('REEF.Visits')}</h3>
            <div id="REEFVisitesTypes">
                    {showError ?
                (load ? FilteredVisit.map(
                    card => (

                    <div className="REEFCard" key={card.id} style={{backgroundImage:`url(${Network.REEF.getImages()+card.Background_Image['id']})`,backgroundSize:"cover"}}>
                                <div className="REEFContent">
                                <h4 className="REEFTitle">{card.translations[0] ? card.translations[0].Title : "Title"}</h4>
                                <span className="ExplorBtn" onClick={() => {changeModalContent(card)}}> {t('REEF.btn')}<span><FontAwesomeIcon icon={faArrowRight} /></span></span>
                                </div>
                    </div>
                )
                ):<Loading />) 
                : 
                (<Error />)
            } 
                </div>
        </div>


            { openModal && <div className="overlay" >
            {modalContent.map(
                card=>
                <div key={card.id} className="modalCard">
                    <h3 style={localStorage.getItem("Lang") === 'UK' ? {textAlign:"left"}: {textAlign:"right",borderRight:"5px #f66216 solid",borderLeft:"0px #f66216 solid",paddingLeft:"0", paddingRight:"10px"}}>{card.translations[0] ? card.translations[0].Title : "Title"}</h3>
                    <p style={localStorage.getItem("Lang") === 'UK' ? {textAlign:"left"}: {textAlign:"right"}}>{card.translations[0] ? card.translations[0].Brief : "Brief"}</p>
                    <div className="slider">
                    <Swiper 
                    centeredSlides = {true}
                    effect="coverflow"
                    grabCursor={true}
                    modules={[Navigation, Pagination, Scrollbar, A11y , EffectCoverflow]}
                    navigation
                    pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                    spaceBetween={10}
                    slidesPerView={1}
                    >
    
                    {card.Images.map(
                       img => 
                    //    <SwiperSlide key={img.REEF_Reference_Images_id["id"]}>{img.REEF_Reference_Images_id["Image_Title"]}</SwiperSlide>
                    <SwiperSlide key={img.directus_files_id["id"]}><img src={Network.REEF.getImages()+img.directus_files_id["id"]} alt={img.directus_files_id["id"]}/></SwiperSlide>

                   )}
                   {/* <SwiperSlide><img src="https://www.innovationnewsnetwork.com/wp-content/uploads/2020/10/Capturing-and-storing-solar-energy-696x392.jpg"></img></SwiperSlide> */}
                    </Swiper>
                    </div>
                    
                    <button style={localStorage.getItem("Lang") === 'UK' ? {right:"0"}: {left:"0",right:"auto"}} className="closeBtn" onClick={() => {setOpenModal(false)}}> {t('REEF.closeBtn')}</button>
                </div>)}
            </div>}

        </div>
        );
};

export {REEF};


