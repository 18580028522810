import React ,{useEffect,useState} from "react";
import './Careers.css'
import career from '../../assets/career.svg'
import resume from '../../assets/resume.svg'
import Network from "../../modules/Network/Network";
import Loading from "../../components/Loading/Loading";
import Error from "../../components/Error/Error";
import { faCircleCheck} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

const Careers= () => {
    const [t,i18n] = useTranslation();
    const [dataa, setdataa] = useState([]);
    const [load, setload] = useState(false);
    const [openModal , setOpenModal] =useState(false);
    const [showError, setshowError] = useState(true);
    const [vacancyData,setVacancyData] =useState({
        Name: "", 
        Gender: "Male", 
        Mobile_Phone: "", 
        Email_Address: "", 
        Years_Of_Experience: 0, 
        Current_OR_Last_Position: "",
        Study_Major: "",
        status: "published" 
    });

    const handleInput = (event) =>{
        setVacancyData({...vacancyData,[event.target.name]:event.target.value})
    }

    function handleSubmit(event)
    {
        event.preventDefault();
        var data = JSON.stringify({
            query: `mutation 
                Create_Applicants_Requests_item {
                    create_Applicants_Requests_item(
                        data: {
                            Name: "${vacancyData.Name}", 
                            Gender: "${vacancyData.Gender}", 
                            Mobile_Phone: "${vacancyData.Mobile_Phone}", 
                            Email_Address: "${vacancyData.Email_Address}", 
                            Years_Of_Experience: ${vacancyData.Years_Of_Experience}, 
                            Current_OR_Last_Position: "${vacancyData.Current_OR_Last_Position}",
                            Study_Major: "${vacancyData.Study_Major}", 
                            status: "published"
                        }
                    ) 
                    {
                        date_created
                        status
                        id
                    }
            }
            `
          });
        Network.Career.applyJob(data)
        .then(function (response)
        {   
           
           setOpenModal(true)
           setVacancyData({
        Name: "", 
        Gender: "", 
        Mobile_Phone: "", 
        Email_Address: "", 
        Years_Of_Experience: 0, 
        Current_OR_Last_Position: "",
        Study_Major: "",
            })
        })
        .catch(function (error) {
          console.log(error);
          setshowError(false)
        });
    }
    
    useEffect(() => {
        localStorage.getItem("Lang") === 'UK' ? i18n.changeLanguage('en') : i18n.changeLanguage('ar');
        Network.Career.getCareers()
        .then(function (response)
        {   
           setdataa(response.data['data']);
           setload(true);
        })
        .catch(function (error) {
          console.log(error);
          setshowError(false)
        });
    }, [])



        return (
        <div className="container">
    { 
    showError ? (load ? <>
            <h2 className={localStorage.getItem("Lang") === 'UK' ? "eng": "arb"}>
                {dataa["Carrers_Intro"][0].translations[0].Title}
            </h2>
            {/* first section intro */}
            <div className="careerSection" id="careerIntro" style={localStorage.getItem("Lang") === 'UK' ? {flexDirection:"row"}: {flexDirection:"row-reverse"}}>
                <div>
                    
                    <p style={localStorage.getItem("Lang") === 'UK' ? {textAlign:"left"}: {textAlign:"right"}}>{dataa["Carrers_Intro"][0].translations[0].Subtitle}</p>
                <ul>
                    {showError ?
                    (dataa["Carrers_Intro"][0].translations[0].Scope.map(
                        (scope,index) => (

                        <li className={localStorage.getItem("Lang") === 'UK' ? "eng": "arbSub"} key={index}>{scope}</li>
                    )
                    )) 
                    : 
                    (<Error />)} 
                </ul>  
                        
                    
                </div>
                <img src={career} alt="carees SVG"/>
            </div>
            {/* Second Section open vacancy */}
            <div className="careerSection" >
                <h3 style={localStorage.getItem("Lang") === 'UK' ? {borderLeft: "5px solid #f66216",paddingLeft:"5px"}: {textAlign:"Right",borderRight: "5px solid #f66216",paddingRight:"5px"}}>{t('Careers.Vacancy')}</h3>
                <div id="vacancyContainer">
                
                {showError ?
                    (dataa["Vacancies"].map(
                        (Vacancy,index) => (

                            <div className="Vacancy" key={index}>
                            <h3 className="vacancyTitle">{Vacancy.translations[0] ? Vacancy.translations[0].Job_Title : "Job title"}</h3>
                            <div className="exp">
                                <span className="expLvl">{Vacancy.translations[0] ? Vacancy.translations[0].Grade : "Grade"}</span>
                                <span className="expYears">+{Vacancy.translations[0] ? Vacancy.translations[0].Years_Of_Experience: "2"} {t('Careers.Years')}</span>
                            </div>
                            <div className="job">
                                <span className="jobplace">{t('Careers.place')}</span>
                                <span className="jobType">{Vacancy.translations[0] ? Vacancy.translations[0].Working_Nature : "fulltime"}</span>
                            </div>
                            
                        </div>
                    )
                    )) 
                    : 
                    (<Error />)} 
                </div>
            </div>
            {/* last section application Form */}
            <div className="careerSection">
                <div id="careerForm">
                    
                    <div id="SVG-Div">
                        <img src={resume} alt=""/>
                    </div>
                    <div id="formDiv">
                    <form onSubmit={handleSubmit} >
                            <label>{t('Careers.form.Name')}</label>
                            <input type="text" name="Name" value ={vacancyData.Name} placeholder="Enter your full name" onChange={handleInput} autocomplet="on"  required/>
                            <label>{t('Careers.form.Gender.Gender')}</label>
                            {/* <input type="" placeholder="" name="Gender" value ={vacancyData.Gender} autocomplet="on" onChange={handleInput} required/> */}
                            <select value={vacancyData.Gender} name="Gender" onChange={handleInput}>
                                <option  value="Male">{t('Careers.form.Gender.male')}</option>
                                <option  value="Female">{t('Careers.form.Gender.female')}</option>
                            </select>
                            <label>{t('Careers.form.Mobile')}</label>
                            <input type="" placeholder="01xxxxxxxxx"autocomplet="on" onChange={handleInput} value ={vacancyData.Mobile_Phone} pattern="[0-9]{11}" name="Mobile_Phone" maxLength={11} minLength={11} required/>
                            <label>{t('Careers.form.Email')}</label>
                            <input type="email" placeholder="john.doe@email.com"autocomplet="on" onChange={handleInput} value ={vacancyData.Email_Address} name="Email_Address" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"/>
                            <label>{t('Careers.form.YearsOfExp')}</label>
                            <input type="number" placeholder="2 5 6 etc" autocomplet="on" onChange={handleInput} value ={vacancyData.Years_Of_Experience} name="Years_Of_Experience" required/>
                            <label>{t('Careers.form.Position')}</label>
                            <input type="text" placeholder="PMS Engineer" autocomplet="on" onChange={handleInput} value ={vacancyData.Current_OR_Last_Position}  name="Current_OR_Last_Position" required/>
                            <label>{t('Careers.form.Study')}</label>
                            <input type="text" placeholder="Engineering" autocomplet="on" onChange={handleInput} value ={vacancyData.Current_OR_Last_Position} name="Study_Major" required/>
                            <button type="Submit" className="btn">{t('Careers.form.apply')}</button>
                        </form >
                    </div>
                </div>
            </div></> :<Loading />):<Error />
    }         

       {openModal && <div className="overlay" >
                <div className="notificationCard">
                <FontAwesomeIcon icon={faCircleCheck} />
                    <h3>{t('Careers.Notification.header')}</h3>
                    <h6>{t('Careers.Notification.message')}</h6>
                    <button className="closeBtn" onClick={() => {setOpenModal(false)}}>{t('Careers.Notification.btn')}</button>
                </div>
            
        </div>}
        </div>

    

        );
};

export {Careers};