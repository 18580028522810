import React  , {useEffect,useState} from "react";
import contentBG from '../../assets/bg2.jpg'
import Network from "../../modules/Network/Network";
import './About.css';
import Loading from "../../components/Loading/Loading";
import Error from "../../components/Error/Error";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullseye , faEye , faPeopleCarryBox,
         faPeopleArrows,faSeedling,faArrowsToEye , faArrowUpRightDots, faTrowelBricks} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "react-i18next";
var aboutData ={};

    const About= () => {
    const [t,i18n] = useTranslation();
    const [dataa, setdataa] = useState('');
    const [load,setload]= useState(false);
    const [showError, setshowError] = useState(true);
    useEffect(() => {
            localStorage.getItem("Lang") === 'UK' ? i18n.changeLanguage('en') : i18n.changeLanguage('ar');
            Network.about.getAboutData()
            .then(function (response) {
               aboutData = {h3:(response.data['data']['About_Us'][0].translations[0]['Header'])
               ,p1:(response.data['data']['About_Us'][0].translations[0]['Content_A']),
               p2:(response.data['data']['About_Us'][0].translations[0]['Content_B']),
               Core_Values:response.data['data']['About_Us'][0].translations[0]['Core_Values'],
               Financial_Considerations:response.data['data']['About_Us'][0].translations[0]['Financial_Considerations'],
               Goals:response.data['data']['About_Us'][0].translations[0]['Goals'],
               Mission:response.data['data']['About_Us'][0].translations[0]['Mission'],
               Purpose:response.data['data']['About_Us'][0].translations[0]['Purpose'],
               Scope_of_Work:response.data['data']['About_Us'][0].translations[0]['Scope_of_Work'],
               vision:response.data['data']['About_Us'][0].translations[0]['vision'],  
            };
               setdataa(aboutData);
               setload(true)
            })
            .catch(function (error) {
              console.log(error);
              setshowError(false);
            });
        }, [])
    
    

        return (
<div className="container">
            <h2 className={localStorage.getItem("Lang") === 'UK' ? "eng": "arb"}>
                {t('About.title')}
            </h2>
            {
               showError ? (load ? <div  id="aboutContainer" >
                <h3 style={localStorage.getItem("Lang") === 'UK' ? {textAlign:"left"}: {textAlign:"right"}}>{dataa.h3}</h3>
                <div id="firstParag" style={localStorage.getItem("Lang") === 'UK' ? {flexDirection:"row"}: {flexDirection:"row-reverse"}}> 
                    <div>
                        <p style={localStorage.getItem("Lang") === 'UK' ? {textAlign:"left"}: {textAlign:"right"}}>{dataa.p1}</p>
                        <p style={localStorage.getItem("Lang") === 'UK' ? {textAlign:"left"}: {textAlign:"right"}}>{dataa.p2}</p>
                    </div>
                    <img className={localStorage.getItem("Lang") === 'UK' ? "eng": "aboutImg"} src={contentBG} alt='Company profile'/>
                </div>
                <hr></hr>
                <div id="secondParag" >
                    <div id="purpose" className="card">
                        <FontAwesomeIcon icon={faBullseye} />
                        <h3>{t('About.secondParagraph.card1')}</h3>
                        <p>{dataa.Purpose}</p>
                    </div>
                    <div id="vision" className="card">
                    <FontAwesomeIcon icon={faEye} />
                        <h3>{t('About.secondParagraph.card2')}</h3>
                        <p>{dataa.vision}</p>                   
                         </div>
                    <div id="mission" className="card">
                    <FontAwesomeIcon icon={faPeopleCarryBox} />
                        <h3>{t('About.secondParagraph.card3')}</h3>
                        <p>{dataa.Mission}</p>
                    </div>
                </div>
                <hr></hr>
                <div id="thirdParag" >
                    <div id="values">
                        <h3>{t('About.thirdParagraph.card1')}</h3>
                            <ul >
                                <li style={localStorage.getItem("Lang") === 'UK' ? {textAlign:"left"}: {textAlign:"right",justifyContent:"space-between"}}><FontAwesomeIcon icon={faPeopleArrows} />{dataa.Goals[0]}</li>
                                <li style={localStorage.getItem("Lang") === 'UK' ? {textAlign:"left"}: {textAlign:"right",justifyContent:"space-between"}}><FontAwesomeIcon icon={faSeedling} />{dataa.Goals[1]}</li>
                                <li style={localStorage.getItem("Lang") === 'UK' ? {textAlign:"left"}: {textAlign:"right",justifyContent:"space-between"}}><FontAwesomeIcon icon={faArrowsToEye} />{dataa.Goals[2]}</li>
                            </ul>
                    </div>
                    <div id="goals">
                    <h3>{t('About.thirdParagraph.card2')}</h3>
                    
                    <ul>
                        <li style={localStorage.getItem("Lang") === 'UK' ? {textAlign:"left"}: {textAlign:"right",justifyContent:"space-between"}}><FontAwesomeIcon icon={faArrowUpRightDots} />{dataa.Core_Values[0]}</li>
                        <li style={localStorage.getItem("Lang") === 'UK' ? {textAlign:"left"}: {textAlign:"right",justifyContent:"space-between"}}><FontAwesomeIcon icon={faTrowelBricks}/>{dataa.Core_Values[1]}</li>
                    </ul>
                    </div>
                </div>
                <hr></hr>
                <div id="forthParag">
                    <div id="scope">
                                <h3>{t('About.forthParagraph.title')}</h3>
                                    <p style={{textAlign: "center"}}>{dataa.Scope_of_Work}</p> 
                    </div>
                    <hr></hr>
                    <div id="financial">
                            <h3>{t('About.forthParagraph.title2')}</h3>
                            
                            <p style={{textAlign: "center"}}>{dataa.Financial_Considerations}</p>
                    </div>
                </div>
                <hr></hr></div> :<Loading />):<Error/>
            }
</div>
            
        );
};

export {About};