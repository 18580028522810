import React, { Component } from 'react'
import './Error.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSadTear} from '@fortawesome/free-solid-svg-icons';
export default class Loading extends Component {
  render() {
    return (
    <div id='Error'>
        
        <FontAwesomeIcon icon={faSadTear} shake style={{animationIterationCount:1,animationDelay:"1s"}}/> 
        <h3>
        Whooops ! looks like somthing went wrong , please check again later.
        </h3>
    </div>
    )
  }
}
