import React, { Component } from 'react'
import './Loading.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner} from '@fortawesome/free-solid-svg-icons';
export default class Loading extends Component {
  render() {
    return (
      <div id='loading'>
        <FontAwesomeIcon icon={faSpinner} spin />
        </div>
    )
  }
}
