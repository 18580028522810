import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ENtrns from './trns/en.json'
import ARBtrns from './trns/ar.json'

const resources = {
  en: {
    translation: ENtrns
  },
  ar: {
    translation: ARBtrns
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en", 
    keySeparator: '.',
    interpolation: {
      escapeValue: false 
    }
  });

  export default i18n;