import { faSun, faWind } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React ,{useEffect,useState} from "react";
import './PR.css'
import Network from "../../modules/Network/Network";
import Loading from "../../components/Loading/Loading";
import Error from "../../components/Error/Error";
import { useTranslation } from "react-i18next";

const ProjectRef= () => {
    const [t,i18n] = useTranslation();
    const [dataa, setdataa] = useState([]);
    const [load, setload] = useState(false);
    const [openModal , setOpenModal] =useState(false);
    const [modalContent,setModalContent] = useState([]);
    const [showError, setshowError] = useState(true);
    const [filterType , setFilterType]=useState("");
    const Filtered = dataa.filter((dataa)=>
    {
        if(filterType === "Solar")
        {
            return dataa.Type === "Solar"
            
        }
        else if(filterType === "Wind")
        {
            return dataa.Type === "Wind"
        }
        else
        {
            return dataa;
            
        }
    })
    const changeModalContent = (card) => {
        setModalContent([card]);
        setOpenModal(true);
    }
    useEffect(() => {
        localStorage.getItem("Lang") === 'UK' ? i18n.changeLanguage('en') : i18n.changeLanguage('ar');
            Network.projects.getProjects()
            .then(function (response)
            {   
            setdataa(response.data['data']['Projects_Reference'])
            setload(true);
            })
            .catch(function (error) {
              console.log(error);
              setshowError(false);
            });
        }, [])


        return (
        <div className="container" id="pContainer">
            <h2 className={localStorage.getItem("Lang") === 'UK' ? "eng": "arb"}>
            {t('Projects.title')}
            </h2>
        
        <div id="ProjectTypes">
            <div className="input-container" id="allTypes" >
                <input id="allRadio"  className="radio-button" value="all" onChange={e=>setFilterType(e.target.value)} type="radio" name="radio" defaultChecked/>
            <div className="radio-tile">
                    {/* <div className="icon bike-icon">
                    <FontAwesomeIcon icon={faWind} />
                    </div> */}
                    <label htmlFor="allRadio" className="radio-tile-label">{t('Projects.allProjects')}</label>
                </div>
            </div>
            <div className="input-container" id="Solar">
                <input id="solarRadio"  className="radio-button" onChange={e=>setFilterType(e.target.value)} value="Solar" type="radio" name="radio" />
            <div className="radio-tile">
                    <div className="icon bike-icon">
                    <FontAwesomeIcon icon={faSun} />
                    </div>
                    <label htmlFor="solarRadio" className="radio-tile-label">{t('Projects.solarProjects')}</label>
                </div>
            </div>
            <div className="input-container" id="wind">
                <input id="windRadio"  className="radio-button" value="Wind" onChange={e=>setFilterType(e.target.value)} type="radio" name="radio"/>
            <div className="radio-tile">
                    <div className="icon bike-icon">
                    <FontAwesomeIcon icon={faWind} />
                    </div>
                    <label htmlFor="windRadio" className="radio-tile-label">{t('Projects.windProjects')}</label>
                </div>
            </div>
            
        </div>
        
            <div id="projectsContainer">
                {showError ? (load ? Filtered.map(
                card => (<div className="project" key={card.id} style={{background:`url(${card.Image ? Network.projects.getImages()+card.Image['id'] : card.Image_URL })`,backgroundSize:"contain",backgroundRepeat:"no-repeat"}}>
                            <div className="Pcontent" style={localStorage.getItem("Lang") === 'UK' ? {}: {textAlign:"right"}}>
                                <div className="Pinfo">
                                    <span className="Plocation">{card.translations[0] ? card.translations[0].Region : "Region"}</span>
                                    <div className="Pdate">
                                        <p className="Pstart">{card.Date_From}</p>- 
                                        <p className="Pend">{card.Date_To}</p>
                                    </div>
                                </div>
                                <div style={localStorage.getItem("Lang") === 'UK' ? {}: {paddingRight:"5px",paddingLeft:"0",borderRight:"5px solid #f66216",borderLeft:"0px solid #f66216"}} className="Pclient">{card.translations[0] ? card.translations[0].Tag : "Tag"}</div>
                                <div className="Ptype">{card.translations[0] ? card.translations[0].SubTitle : "Subtitle"}</div>
                                <h1 style={localStorage.getItem("Lang") === 'UK' ? {maxWidth:"20ch"}: {maxWidth:"none"}} className="Ptitle">{card.translations[0] ? card.translations[0].Title : "Title"}</h1>
                                <p className="Pbrief">{card.translations[0]? card.translations[0].Detailed_Content : "Detailed Content"}</p>
                                <button className="btn" onClick={() => {changeModalContent(card)}}>{t('Projects.btn')}</button>
                            </div>
                </div>)):<Loading />):<Error/>}
              
            </div>    
            { openModal && <div className="overlay" >
             {modalContent.map(
                card=>
                <div key={card.id} className="modalCard">
                    <h3 style={localStorage.getItem("Lang") === 'UK' ? {textAlign:"left"}: {textAlign:"right",borderRight:"5px #f66216 solid",borderLeft:"0px #f66216 solid",paddingLeft:"0", paddingRight:"10px"}}>{card.translations[0] ? card.translations[0].Title : "Title"}</h3>
                    <p style={localStorage.getItem("Lang") === 'UK' ? {textAlign:"left"}: {textAlign:"right"}}>{card.translations[0]? card.translations[0].Detailed_Content : "Detailed Content"}</p>
                    <button style={localStorage.getItem("Lang") === 'UK' ? {right:"0"}: {left:"0",right:"auto"}} className="closeBtn" onClick={() => {setOpenModal(false)}}>{t('Projects.closeBtn')}</button>
                </div>)}
        </div>}
        </div>
        );
};

export {ProjectRef};