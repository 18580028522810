import axios from "axios";
import {CareersData,ContactData,AboutData,
        HomeData, ServicesData, ProjectsData,
        REEFData, NewsData} from "./query.js"
const baseURL = "https://www.triplem-const.com:8443/graphql";
axios.defaults.baseURL = baseURL;
axios.defaults.headers = {'Content-Type': 'application/json'}


export default {
  about: {
    getAboutData() {
      return axios.post("",AboutData);
    },
        },
  services: {
    getServices(){
      return axios.post("",ServicesData);
          }
        },
  projects: {
    getProjects(){
      return axios.post("",ProjectsData);
            },
            getImages()
    {
      return "https://www.triplem-const.com:8443/assets/"
    }
              },
  news: {
         getNews(){
                  return axios.post("",NewsData);
                      },
                      
    getImages()
    {
      return "https://www.triplem-const.com:8443/assets/"
    }
       },
  REEF: {
    getREEF(){
          return axios.post("",REEFData);
              },
    getImages()
    {
      return "https://www.triplem-const.com:8443/assets/"
    }
        },
  Career:
  {
    getCareers(){
      return axios.post("",CareersData);
          },
    applyJob(body)
    {
      return axios.post("",body);
    }
  },
  Home: 
  {
    getHomeContent(){
      return axios.post("",HomeData);
          },
  },
  Contact:
  {
    getContactInfo()
    {
      return axios.post("",ContactData);
    }
  },
}
