var lang = localStorage.getItem("Lang") === 'UK' ? lang='en-US' : lang='ar-SA';
const CareersData = JSON.stringify({  
    query: `{
        Carrers_Intro (filter: {status: {_contains: "published"}}){
            status
            translations(filter: {languages_code: {_contains:"${lang}"}}) {
                Title
                Subtitle
                Scope
            }
        }
        Vacancies {
            translations (filter: {languages_code: {_contains: "${lang}"}})
            {
            Job_Title
            Grade
            Years_Of_Experience
            Working_Nature}
        }}`});
const ContactData = JSON.stringify({
            query: `
            {
            Contact_US(filter: {status: {_contains: "published"}}) {
            id
            translations(filter: {languages_code: {_contains: "${lang}"}}) {
                languages_code
                Mobile_Number
                Tele_Fax
                Email_Address
                Address
            }
            Banner_Image {
                id
                    }  
                }
            }
            `
});
const AboutData = JSON.stringify({
    query: `{
    __typename
    About_Us (filter: {status: {_contains: "published"}}){
        translations(filter: {languages_code: {_contains: "${lang}"}}) {
            languages_code
            Header
            Content_A
            Content_B
            Purpose
            vision
            Mission
            Scope_of_Work
            Core_Values
            Goals
            Financial_Considerations
        }
    }
    
  }`
  });
const HomeData = JSON.stringify({
    query: `{   
            Home (filter: {status: {_contains: "published"}}){
                id
                translations(filter: {languages_code: {_contains: "${lang}"}}) {
                History_Title
                History_Year
                History_Content
                }
            }
            Partners {
                id
                status
                Icon_URL
                translations(filter: {languages_code: {_contains: "${lang}"}}) {
                languages_code
                Name
            }
            }
            Home_Staff_Analysis {
                id
                translations(filter: {languages_code: {_contains: "${lang}"}}) {
                    languages_code
                    Employee
                    Managers__administers
                    Field_Engineers
                    supervisors_and_workers
                }
            }
        
  }`
  });
const ServicesData = JSON.stringify({
    query: `
    {
        Services (filter: {status: {_contains: "published"}}) {
            id
            status
            translations(filter: {languages_code: {_contains: "${lang}"}}) {
                Service_Title
                Service_Content
                Service_Detailed_Content
            }
            Service_Image {
                id
                filename_download
                filename_disk
                title
            }
            
        }
    }
    
    `
  });
const ProjectsData = JSON.stringify({
    query: `{
  
        Projects_Reference (filter: {status: {_contains: "published"}}) {
            translations(filter: {languages_code: {_contains: "${lang}"}}){
                Region
                Title
                SubTitle
                Tag
                Content
                Detailed_Content
            }
            Image {
                id
                filename_disk
            }
            id
            status
            Image_URL
            Date_To
            Date_From
            Type 
        }
  }`
  });
const REEFData = JSON.stringify({
    query: `{
      Who_We_Are {
            status
            translations (filter: {languages_code: {_contains: "${lang}"}}){
                languages_code
                Who_We_Are
            }
            id
        }
        REEF (filter: {status: {_contains: "published"}}) {
            id
            status
            translations(filter: {languages_code: {_contains: "${lang}"}}) {
                Title
                Brief
            }
            Category
            Background_Image {
                id
            }
            Reef_Type
            Images {
                directus_files_id {
                    id
                }
            }
        }
  }`
  });
const NewsData = JSON.stringify({
    query: `{
    News(filter: {status: {_contains: "published"}}) {
        translations(filter: {languages_code: {_contains:"${lang}"}}) {
            Title
            Owner
            Content
            Detailed_Content
            News_Content
        }
            id
            status
            sort
            user_created
            date_created
            user_updated
            date_updated
            Image_URL
            Date
            Images {
                directus_files_id {
                    id
                }
            }
    }
    }`
  }); 

export {CareersData,ContactData,
        AboutData,HomeData,ServicesData,
        ProjectsData,NewsData,REEFData}