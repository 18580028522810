import React from "react";
import './HomePage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChevronDown}  from '@fortawesome/free-solid-svg-icons'
import {useEffect,useState} from "react";
import Network from "../../modules/Network/Network";
import Loading from "../../components/Loading/Loading";
import Error from "../../components/Error/Error";
import { useTranslation } from "react-i18next";
const HomePage = () => {
    const [t,i18n] = useTranslation();
    const [dataa, setdataa] = useState([]);
    const [partenersdataa, setPdataa] = useState([]);
    const [Employeedataa, setEdataa] = useState([]);
    const [showError, setshowError] = useState(true);
    const [load, setload] = useState(false);

    useEffect(() => {
        localStorage.getItem("Lang") === 'UK' ? i18n.changeLanguage('en') : i18n.changeLanguage('ar');
        Network.Home.getHomeContent()
        .then(function (response)
        {  
            setdataa(response.data.data.Home);
            setEdataa(response.data.data.Home_Staff_Analysis[0].translations[0]);
            setPdataa(response.data.data.Partners);
            setload(true); 
        })
        .catch(function (error) {
          console.log(error);
          setshowError(false);
        });
    }, [])
   
        return (
        <div id="homeContainer">
           <section id="intro">
                <p id="title">TRIPLE-M</p>
                <div id="slogan">
                    <ul> 
                        <li><p>{t('Home.header.scroll1')}</p></li>
                        <li><span>{t('Home.header.scroll2')}</span></li>
                        <li><span>{t('Home.header.scroll3')}</span></li>
                        <li>{t('Home.header.scroll4')}</li>
                        <li>{t('Home.header.scroll5')}</li>
                    </ul> 
                </div>
                <div id="scrollDown">
                <FontAwesomeIcon icon={faChevronDown}/>
                <FontAwesomeIcon icon={faChevronDown}/>
                </div>
           </section>
           
           <section className="timeline">
            <div className="sectionTitle">{t('Home.history')}</div>
                <ul>
                    {showError ? (load ? dataa.map(
                        history => <li key={history.id}>
                        <div >
                            <time>{history.translations[0] ? history.translations[0].History_Year : "2023"}</time>
                            <h1>{history.translations[0] ? history.translations[0].History_Title : "Title"}</h1>
                            <p>
                            {history.translations[0] ? history.translations[0].History_Content : "Content"}</p>
                            
                        </div>
                    </li>
                    ):<Loading/>):<Error/>}
                    
                    
                </ul>
            </section>

            <section id="team">
            {/* <div className="sectionTitle">Our Team</div> */}
            <div id="teamCount">
                <div>
                    <span>{Employeedataa.Employee}<span className="plus">+</span></span>
                    <label>{t('Home.staff.Employee')}</label>
                </div>
                <div>
                    <span>{Employeedataa.Managers__administers} <span className="plus">%</span></span>
                    <label>{t('Home.staff.Managers')}</label>
                </div>
                <div>
                    <span>{Employeedataa.Field_Engineers} <span className="plus">%</span></span>
                    <label>{t('Home.staff.FieldENG')}</label>
                </div>
                <div>
                    <span>{Employeedataa.supervisors_and_workers} <span className="plus">%</span></span>
                    <label>{t('Home.staff.Workers')}</label>
                </div>
            </div>
            </section>
 
            <section id="partners">
            <div className="sectionTitle">{t('Home.partners')}</div>
                <div id="partnersSlider">
                {showError ? (load ? partenersdataa.map(
                    partner => 
                    <div className='partnerContainer' key={partner.id}>
                    <img src={partner.Icon_URL}  alt={partner.translations[0] ? partner.translations[0].Name: "partnerName" }></img>
                </div>
                ):<Loading/>):<Error/>}
                
                </div>
            </section>

            

        </div>
        );
};

export { HomePage };