import React , {useState , useEffect}  from "react";
import { Link } from "react-router-dom";
import "./navBar.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faBars, faClose} from '@fortawesome/free-solid-svg-icons'
import logo from '../../assets/logo.png';
import UK from '../../assets/uk.png';
import EGY from '../../assets/egy.png';
import { useTranslation } from "react-i18next";
const AppNavBar = () => {
  const [t,i18n] = useTranslation();
  const [showNavbar, setShowNavbar] = useState(false);
  const [Lang, setLang] = useState(UK);

  const toggleNav = () => {
    setShowNavbar(!showNavbar);
  };

  useEffect(() => {
    localStorage.getItem("Lang") === 'UK' ? setLang(EGY) : setLang(UK);
    localStorage.getItem("Lang") === 'UK' ? i18n.changeLanguage('en') : i18n.changeLanguage('ar');
  }, []);
  
  const toggleLang = () => {
    
    Lang === UK ? setLang(EGY) : setLang(UK);
    Lang === UK ? localStorage.setItem("Lang", "UK") : localStorage.setItem("Lang", "EGY");
    window.location.reload();
  };

  
  return (
    <nav id="NavBar">
      <label  htmlFor="check" id="navBtn" onClick={toggleNav} style={{color: showNavbar ? '#0076c8' : 'white' , backgroundColor : showNavbar ? 'white' : 'transparent' }}>
          <FontAwesomeIcon icon={showNavbar ? faClose : faBars}/>
      </label>
      <span id="logo">
        <figure>
          <Link to="/">
              <img src={logo} alt="MMM logo"/>
          </Link>
        </figure>
      </span>
        <ul style={{right: showNavbar ? '0' : '-100%'}} >
          <li><img onClick={toggleLang} id="lang" src={Lang} alt="Lang"/></li>
          <li><Link to="/" onClick={toggleNav}>{t('NavBar.home')}</Link></li>
          <li><Link to="/about" onClick={toggleNav}>{t('NavBar.about')}</Link></li>
          <li><Link to="/services" onClick={toggleNav}>{t('NavBar.services')}</Link></li>
          <li><Link to="/projectReference" onClick={toggleNav}>{t('NavBar.projects')}</Link></li>
          <li><Link to="/REEF" onClick={toggleNav}>{t('NavBar.reef')}</Link></li>
          <li><Link to="/News" onClick={toggleNav}>{t('NavBar.news')}</Link></li>
          <li><Link to="/Careers" onClick={toggleNav}>{t('NavBar.careers')}</Link></li>
          <li><Link to="/Contactus" onClick={toggleNav}>{t('NavBar.contact')}</Link></li>
        </ul>
    </nav>
  );
};

export { AppNavBar };
