import React ,{useEffect,useState}  from "react";
import './News.css'
import Network from "../../modules/Network/Network";
import Loading from "../../components/Loading/Loading";
import Error from "../../components/Error/Error";
import {Swiper,SwiperSlide} from 'swiper/react';
import { useTranslation } from "react-i18next";
import {Navigation,Pagination,A11y,Scrollbar , EffectCoverflow} from 'swiper'




  function getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString('en-US', { month: 'short' });
  }
  
const News= () => {
    const [t,i18n] = useTranslation();
    const [dataa, setdataa] = useState([]);
    const [load, setload] = useState(false);
    const [showError, setshowError] = useState(true);
    const [openModal , setOpenModal] =useState(false);
    const [modalContent,setModalContent] = useState([]);
    const changeModalContent = (card) => {
        setModalContent([card]);
        setOpenModal(true);
    }
    useEffect(() => {
        localStorage.getItem("Lang") === 'UK' ? i18n.changeLanguage('en') : i18n.changeLanguage('ar');
            Network.news.getNews()
            .then(function (response)
            {   
               setdataa(response.data['data']['News']);    
               setload(true);
              
            })
            .catch(function (error) {
              console.log(error);
              setshowError(false);
            });
        }, [])

        return (
        <div className="container">
            <h2 className={localStorage.getItem("Lang") === 'UK' ? "eng": "arb"}>
            {t('News.title')}
            </h2>
            <div id="newsContainer">
            {
            showError ?
            (load ? dataa.map(
                card => (
                <div key={card.id} className="newscard">
                    
                    <div className="wrapper" style={{backgroundImage:`url(${card.Image_URL})`,backgroundSize:"cover"}}>
                            <div className="date">
                                <span className="day">{card.Date.slice(8)}</span>
                                <span className="month">{getMonthName(card.Date.slice(5,7))}</span>
                                <span className="year">{card.Date.slice(0,4)}</span>
                            </div>
                            <div className="data">
                                <div className="content">
                                    <span className="author">Auther : <span>{card.translations[0] ? card.translations[0].Owner :"Author"}</span></span>
                                    <h1 className="Newstitle"  onClick={() => {changeModalContent(card)}}>{card.translations[0] ? card.translations[0].Title : "Title"}</h1>
                                    <p className="text">{card.translations[0] ? card.translations[0].Content : "Content"}</p>
                                </div>
                            </div>
                    </div>
                </div>
                )
            ):<Loading />):
            (<Error />)
            }  
               
            </div>
            { openModal && <div className="overlay" >
             {modalContent.map(
                card=>
                <div key={card.id} className="modalCard">
                    <h3 style={localStorage.getItem("Lang") === 'UK' ? {textAlign:"left"}: {textAlign:"right",borderRight:"5px #f66216 solid",borderLeft:"0px #f66216 solid",paddingLeft:"0", paddingRight:"10px"}}>{card.translations[0] ? card.translations[0].Title : "Title"}</h3>
                    {/* <p>{card.Detailed_Content}</p> */}
                    <div className="slider">
                    <Swiper 
                    centeredSlides = {true}
                    effect="coverflow"
                    grabCursor={true}
                    modules={[Navigation, Pagination, Scrollbar, A11y , EffectCoverflow]}
                    navigation
                    pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                    spaceBetween={10}
                    slidesPerView={1}
                    >
    
                    {card.Images.map(
                       img => 
                    <SwiperSlide key={img.directus_files_id["id"]}>
                       <img src={Network.news.getImages()+img.directus_files_id["id"]} alt={img.directus_files_id["id"]}/>
                     </SwiperSlide>
                  
                   )}
                    </Swiper>
                    </div>
                    <div style={localStorage.getItem("Lang") === 'UK' ? {textAlign:"left"}: {textAlign:"right"}} dangerouslySetInnerHTML={card.translations[0] ? {__html: card.translations[0].News_Content || card.translations[0].Detailed_Content} : "Content"} />
                    <button style={localStorage.getItem("Lang") === 'UK' ? {right:"0"}: {left:"0",right:"auto"}} className="closeBtn" onClick={() => {setOpenModal(false)}}>{t('News.btnName')}</button>
                </div>)}
             </div>}
        </div>
        );
};

export {News};