import React  ,{useEffect,useState} from "react";
import { Link } from "react-router-dom";    
import contactUs from '../../assets/contactUs.jpg'
import './Contact.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquarePhone , faSquareEnvelope, faLocationDot} from '@fortawesome/free-solid-svg-icons'
import Network from "../../modules/Network/Network";
import Loading from "../../components/Loading/Loading";
import Error from "../../components/Error/Error";
import { useTranslation } from "react-i18next";




const Contact= () => {
    
    const [t,i18n] = useTranslation();
    const [dataa, setdataa] = useState([]);
    const [load, setload] = useState(false);
    const [showError, setshowError] = useState(true);

    useEffect(() => {
        localStorage.getItem("Lang") === 'UK' ? i18n.changeLanguage('en') : i18n.changeLanguage('ar');
        Network.Contact.getContactInfo()
        .then(function (response)
        {   
           setdataa(response.data['data'].Contact_US[0]);
           setload(true);
        })
        .catch(function (error) {
          console.log(error);
          setshowError(false)
        });
    }, [])
        return (
        <div id="contactContainer" className="container">
           <h2 className={localStorage.getItem("Lang") === 'UK' ? "eng": "arb"}>
                {t('Contact.title')}
            </h2>
            <div id="imgDiv">                   
                        <img src={contactUs} alt="contactus"/>
            </div>
            {showError ? (load ? <> 
            
            <div id="cardsDiv">
                <div id="mobile" className="card">
                <FontAwesomeIcon icon={faSquarePhone} />
                <h3><Link to="tel:$`{{dataa.Mobile_Number}}`">{t('Contact.mobile')} : {dataa.translations[0].Mobile_Number}</Link></h3>
                <h3>{t('Contact.Fax')} : {dataa.translations[0].Tele_Fax}</h3>
                </div>
                <div id="mail" className="card">
                <FontAwesomeIcon icon={faSquareEnvelope} />
                <h3><Link to="mailto:$`{{dataa.Email_Address}}">{t('Contact.Mail')} : {dataa.translations[0].Email_Address}</Link></h3>
                </div>  
                <div id="location" className="card">
                <FontAwesomeIcon icon={faLocationDot} />
                <h3 style={{textAlign: "center"}}><Link target="_blank" to="https://www.google.com/maps/dir//Triple+M+Construction%7C+%D8%AA%D8%B1%D8%A8%D9%84+%D8%A7%D9%85+%7C+%D8%A7%D9%84%D9%88%D8%A7%D8%AD+%D8%A7%D9%84%D8%B7%D8%A7%D9%82%D8%A9+%D8%A7%D9%84%D8%B4%D9%85%D8%B3%D9%8A%D8%A9+%7C+%D8%B4%D8%B1%D9%83%D8%A9+%D8%A8%D9%8A%D8%B9+%D8%A7%D9%84%D9%88%D8%A7%D8%AD+%D8%A7%D9%84%D8%B7%D8%A7%D9%82%D8%A9+%D8%A7%D9%84%D8%B4%D9%85%D8%B3%D9%8A%D8%A9+%7C+%D8%B7%D8%A7%D9%82%D8%A9+%D8%B4%D9%85%D8%B3%D9%8A%D8%A9+%D8%8C+%D8%AA%D9%86%D9%81%D9%8A%D8%B0+%D9%85%D8%AD%D8%B7%D8%A7%D8%AA+%D8%A7%D9%84%D8%B7%D8%A7%D9%82%D8%A9+%D8%A7%D9%84%D8%B4%D9%85%D8%B3%D9%8A%D8%A9+22%D8%B9%D9%85%D8%A7%D8%B1%D8%A7%D8%AA+%D8%A7%D9%84%D8%B4%D8%B1%D9%83%D9%87+%D8%A7%D9%84%D8%B3%D8%B9%D9%88%D8%AF%D9%8A%D8%A9+-+%D8%B4%D8%A7%D8%B1%D8%B9+%D8%A7%D9%84%D9%86%D8%B2%D9%87%D9%87+-+%D8%A3%D9%85%D8%A7%D9%85+%D8%AF%D8%A7%D8%B1+%D8%A7%D9%84%D8%AF%D9%81%D8%A7%D8%B9+%D8%A7%D9%84%D8%AC%D9%88%D9%8A+Nasr+City+Cairo+Governorate+11765%E2%80%AD%E2%80%AD/@30.0757661,31.339858,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x14583f82ec4b740d:0xc794835b0c7c4e2e!2m2!1d31.3398506!2d30.0757668">
                {dataa.translations[0].Address}</Link></h3>
                </div> 
            </div>
            
            </>:<Loading/>):<Error/>
}
<iframe 
            width="100%"
            height="350px"
            id="gmap_canvas" 
            src="https://maps.google.com/maps?q=Triple%20M%20Construction%7C%20%D8%AA%D8%B1%D8%A8%D9%84%20%D8%A7%D9%85%20%7C%20%D8%A7%D9%84%D9%88%D8%A7%D8%AD%20%D8%A7%D9%84%D8%B7%D8%A7%D9%82%D8%A9%20%D8%A7%D9%84%D8%B4%D9%85%D8%B3%D9%8A%D8%A9%20%7C%20%D8%B4%D8%B1%D9%83%D8%A9%20%D8%A8%D9%8A%D8%B9%20%D8%A7%D9%84%D9%88%D8%A7%D8%AD%20%D8%A7%D9%84%D8%B7%D8%A7%D9%82%D8%A9%20%D8%A7%D9%84%D8%B4%D9%85%D8%B3%D9%8A%D8%A9%20%7C%20%D8%B7%D8%A7%D9%82%D8%A9%20%D8%B4%D9%85%D8%B3%D9%8A%D8%A9%20%D8%8C%20%D8%AA%D9%86%D9%81%D9%8A%D8%B0%20%D9%85%D8%AD%D8%B7%D8%A7%D8%AA%20%D8%A7%D9%84%D8%B7%D8%A7%D9%82%D8%A9%20%D8%A7%D9%84%D8%B4%D9%85%D8%B3%D9%8A%D8%A9&t=&z=13&ie=UTF8&iwloc=&output=embed" 
            frameBorder="0" 
            scrolling="no" 
            marginHeight="0" 
            marginWidth="0">

            </iframe>

        </div>
        );
};

export {Contact};