import React ,{useEffect,useState} from "react";
import './Services.css';
import Network from "../../modules/Network/Network";
import Loading from "../../components/Loading/Loading";
import Error from "../../components/Error/Error";
import { useTranslation } from "react-i18next";



const Services= () => {
    const [t,i18n] = useTranslation();
    const [dataa, setdataa] = useState([]);
    const [load, setload] = useState(false);
    const [openModal , setOpenModal] =useState(false);
    const [showError, setshowError] = useState(true);
    const [modalContent,setModalContent] = useState([]);
    const changeModalContent = (card) => {
        setModalContent([card]);
        setOpenModal(true);
    }
    useEffect(() => {
            localStorage.getItem("Lang") === 'UK' ? i18n.changeLanguage('en') : i18n.changeLanguage('ar');
            Network.services.getServices()
            .then(function (response)
            {   
               setdataa(response.data['data']['Services']);
               setload(true);
            })
            .catch(function (error) {
              console.log(error);
              setshowError(false)
            });
        }, [])

        return (
        <div className="container">
            <h2 className={localStorage.getItem("Lang") === 'UK' ? "eng": "arb"}>
            {t('Services.title')}
            </h2>

            
        <div id="cardsContainer">
           
            {showError ? 
                (load ? dataa.map(
                    card => (
                                <div className="cardServ" key={card.id} style={{backgroundImage:`url(${Network.REEF.getImages()+card.Service_Image['id']})`}}>
                                    <div className="content">
                                        <h3 className="title">{card.translations[0] ? card.translations[0].Service_Title : "title"}</h3>
                                        <p className="copy">{card.translations[0] ? card.translations[0].Service_Content: "content"}</p>
                                        <button className="btn" onClick={() => {changeModalContent(card)}}>{t('Services.btn')}</button>
                                    </div>
                                </div>
                    )
                ):<Loading />) 
                : 
                (<Error />)
            }  
        </div>
        { openModal && <div className="overlay" >
             {modalContent.map(
                card=>
                <div key={card.id} className="modalCard">
                    <h3 style={localStorage.getItem("Lang") === 'UK' ? {textAlign:"left"}: {textAlign:"right",borderRight:"5px #f66216 solid",borderLeft:"0px #f66216 solid",paddingLeft:"0", paddingRight:"10px"}}>{card.translations[0] ? card.translations[0].Service_Title: "title"}</h3>
                    <p style={localStorage.getItem("Lang") === 'UK' ? {textAlign:"left"}: {textAlign:"right"}}>{card.translations[0] ? card.translations[0].Service_Detailed_Content : "detailed content"}</p>
                    <button style={localStorage.getItem("Lang") === 'UK' ? {right:"0"}: {left:"0",right:"auto"}} className="closeBtn" onClick={() => {setOpenModal(false)}}>{t('Services.closeBtn')}</button>
                </div>)}
        </div>}
        
     </div>
        );
};

export {Services};