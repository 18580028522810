import React ,{useEffect}from "react";
import './Footer.css';
import { Link } from "react-router-dom";
import logo from '../../assets/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePhone , faSquareEnvelope, faLocationDot} from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faLinkedin} from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";
const Footer = () => 
{const [t,i18n] = useTranslation();
    useEffect(() => {
        localStorage.getItem("Lang") === 'UK' ? i18n.changeLanguage('en') : i18n.changeLanguage('ar');
      }, []);
   return( 
   <footer>
        <div id="footer">
            <div id="footerContact">
                <div id="footerLocation" className="footerContactSection">
                    <FontAwesomeIcon icon={faLocationDot} />
                    <div className="contactData">
                            <h3 style={localStorage.getItem("Lang") === 'UK' ? {textAlign:"left"}:{textAlign:"center",letterSpacing:"0"}}>{t('Footer.find')}</h3>
                            <Link style={localStorage.getItem("Lang") === 'UK' ? {textAlign:"left"}:{textAlign:"center"}} target="_blank" to="https://www.google.com/maps/dir//Triple+M+Construction%7C+%D8%AA%D8%B1%D8%A8%D9%84+%D8%A7%D9%85+%7C+%D8%A7%D9%84%D9%88%D8%A7%D8%AD+%D8%A7%D9%84%D8%B7%D8%A7%D9%82%D8%A9+%D8%A7%D9%84%D8%B4%D9%85%D8%B3%D9%8A%D8%A9+%7C+%D8%B4%D8%B1%D9%83%D8%A9+%D8%A8%D9%8A%D8%B9+%D8%A7%D9%84%D9%88%D8%A7%D8%AD+%D8%A7%D9%84%D8%B7%D8%A7%D9%82%D8%A9+%D8%A7%D9%84%D8%B4%D9%85%D8%B3%D9%8A%D8%A9+%7C+%D8%B7%D8%A7%D9%82%D8%A9+%D8%B4%D9%85%D8%B3%D9%8A%D8%A9+%D8%8C+%D8%AA%D9%86%D9%81%D9%8A%D8%B0+%D9%85%D8%AD%D8%B7%D8%A7%D8%AA+%D8%A7%D9%84%D8%B7%D8%A7%D9%82%D8%A9+%D8%A7%D9%84%D8%B4%D9%85%D8%B3%D9%8A%D8%A9+22%D8%B9%D9%85%D8%A7%D8%B1%D8%A7%D8%AA+%D8%A7%D9%84%D8%B4%D8%B1%D9%83%D9%87+%D8%A7%D9%84%D8%B3%D8%B9%D9%88%D8%AF%D9%8A%D8%A9+-+%D8%B4%D8%A7%D8%B1%D8%B9+%D8%A7%D9%84%D9%86%D8%B2%D9%87%D9%87+-+%D8%A3%D9%85%D8%A7%D9%85+%D8%AF%D8%A7%D8%B1+%D8%A7%D9%84%D8%AF%D9%81%D8%A7%D8%B9+%D8%A7%D9%84%D8%AC%D9%88%D9%8A+Nasr+City+Cairo+Governorate+11765%E2%80%AD%E2%80%AD/@30.0757661,31.339858,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x14583f82ec4b740d:0xc794835b0c7c4e2e!2m2!1d31.3398506!2d30.0757668">
                            {t('Footer.location')} </Link>
                    </div>
                </div>
                <div id="footerMobile" className="footerContactSection">
                    <FontAwesomeIcon icon={faSquarePhone} />
                    <div className="contactData">
                            <h3 style={localStorage.getItem("Lang") === 'UK' ? {textAlign:"left"}:{textAlign:"center",letterSpacing:"0"}}>{t('Footer.call')}</h3>
                            <Link to="tel:01090091008">Mobile: 0102790310</Link>
                            <span>Fax: +2 0224726066</span>
                    </div>
                </div>
                <div id="footerMail" className="footerContactSection">
                    <FontAwesomeIcon icon={faSquareEnvelope} />
                    <div className="contactData">
                            <h3 style={localStorage.getItem("Lang") === 'UK' ? {textAlign:"left"}:{textAlign:"center",letterSpacing:"0"}}>{t('Footer.mail')}</h3>
                            <Link to="mailto:info@triplem-const.com">email : info@triplem-const.com</Link>
                    </div>
                </div>    
            </div>
            <div id="footerSecondSection">
                <div id="footerLogo">
                    <figure>
                        <Link to="/">
                        <img src={logo} alt="MMM logo"/>
                        </Link>
                    </figure>
                    <p>{t('Footer.brief')}</p>
                </div>
                <div id="footerAbout">
                    <h3 style={localStorage.getItem("Lang") === 'UK' ? {}:{textAlign:"center",letterSpacing:"0"}}>{t('Footer.links.name')}</h3>
                    <ul style={localStorage.getItem("Lang") === 'UK' ? {}:{textAlign:"right"}}>
                        <li><Link to="/">{t('Footer.links.home')}</Link></li>
                        <li><Link to="/services">{t('Footer.links.services')}</Link></li>
                        <li><Link to="/about">{t('Footer.links.about')}</Link></li>
                        <li><Link to="/projectReference">{t('Footer.links.projects')}</Link></li>
                        <li><Link to="/REEF">{t('Footer.links.reef')}</Link></li>
                    </ul>
                </div>
                <div id="footerSocial">
                    <h3 style={localStorage.getItem("Lang") === 'UK' ? {}:{textAlign:"center",letterSpacing:"0"}}>{t('Footer.follow')}</h3>
                   <Link target="_blank" to="https://www.facebook.com/triplemconst"><FontAwesomeIcon icon={faFacebook} id="fb"/></Link> 
                    <Link target="_blank" to="https://ae.linkedin.com/company/triple-m-construction"><FontAwesomeIcon icon={faLinkedin} id="linkedin"/></Link>
                    
                </div>
            </div> 
        </div>
        <div id="copyRights">
<span>Copyrights © 2023, Developed by <span style={{marginLeft:"3px",textDecoration:"underline"}}><Link target="_blank"  to="https://alpha-ss.godaddysites.com/" id="AlphaSS">Alpha SS</Link></span></span>
        </div>
   </footer>


   );
}

export {Footer};